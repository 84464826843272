import { createSlice } from "@reduxjs/toolkit";
import { getDeliveryStatus } from "./deliveryStatusThunk";

const initialState = {
  deliveryStatusList: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
};

const deliveryStatusSlice = createSlice({
  name: "deliveryStatusData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryStatus.pending, (state) => {
        state.deliveryStatusList.loading = true;
        state.deliveryStatusList.error = null;
      })
      .addCase(getDeliveryStatus.fulfilled, (state, action) => {
        state.deliveryStatusList.loading = false;
        state.deliveryStatusList.data = action.payload;
        state.deliveryStatusList.hasFetched = true;
      })
      .addCase(getDeliveryStatus.rejected, (state, action) => {
        state.deliveryStatusList.loading = false;
        state.deliveryStatusList.error = action.error.message;
      })
  },
});

export default deliveryStatusSlice.reducer;