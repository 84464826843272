import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { startLoading, stopLoading } from "../loading/loadingSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getShops = createAsyncThunk(
  "get/shops",
  async (_, { dispatch }) => {
    try {
      dispatch(startLoading());
      // const NinetySevenAuth = localStorage.getItem("NinetySevenAuth");
      // const NinetySevenAuthObject = JSON.parse(NinetySevenAuth);
      // const token = NinetySevenAuthObject.token;
      const response = await axios.get(
        `${API_BASE_URL}/api/shop`
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      );
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const getShopByID = createAsyncThunk(
  "get/shopByID",
  async (id, { dispatch }) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(`${API_BASE_URL}/api/shop/${id}`);
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);
