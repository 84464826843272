import { createSlice } from "@reduxjs/toolkit";
import {
  deleteOrderHistory,
  getCompletedOrdersByUserID,
  getOngoingOrdersByUserID,
  getOrderByID,
} from "./orderThunk";

const initialState = {
  CompletedOrders: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
  OngoingOrders: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
  order: {
    data: {},
    loading: false,
    error: null,
    hasFetched: false,
  },
  message: null,
};

const orderSlice = createSlice({
  name: "shopData",
  initialState,
  reducers: {
    clearMessage(state) {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompletedOrdersByUserID.pending, (state) => {
        state.CompletedOrders.loading = true;
        state.CompletedOrders.error = null;
      })
      .addCase(getCompletedOrdersByUserID.fulfilled, (state, action) => {
        state.CompletedOrders.loading = false;
        state.CompletedOrders.data = action.payload;
        state.CompletedOrders.hasFetched = true;
      })
      .addCase(getCompletedOrdersByUserID.rejected, (state, action) => {
        state.CompletedOrders.loading = false;
        state.CompletedOrders.error = action.error.message;
      })
      .addCase(getOngoingOrdersByUserID.pending, (state) => {
        state.OngoingOrders.loading = true;
        state.OngoingOrders.error = null;
      })
      .addCase(getOngoingOrdersByUserID.fulfilled, (state, action) => {
        state.OngoingOrders.loading = false;
        state.OngoingOrders.data = action.payload;
        state.OngoingOrders.hasFetched = true;
      })
      .addCase(getOngoingOrdersByUserID.rejected, (state, action) => {
        state.OngoingOrders.loading = false;
        state.OngoingOrders.error = action.error.message;
      })
      .addCase(getOrderByID.pending, (state) => {
        state.order.loading = true;
        state.order.error = null;
      })
      .addCase(getOrderByID.fulfilled, (state, action) => {
        state.order.loading = false;
        state.order.data = action.payload;
        state.order.hasFetched = true;
      })
      .addCase(getOrderByID.rejected, (state, action) => {
        state.order.loading = false;
        state.order.error = action.error.message;
      })
      .addCase(deleteOrderHistory.pending, (state) => {
        state.CompletedOrders.loading = true;
        state.CompletedOrders.error = null;
      })
      .addCase(deleteOrderHistory.fulfilled, (state, action) => {
        state.CompletedOrders.loading = false;
        state.CompletedOrders.data = [];
        state.message = action.payload.message;
      })
      .addCase(deleteOrderHistory.rejected, (state, action) => {
        state.CompletedOrders.loading = false;
        state.CompletedOrders.error = action.error.message;
      });
  },
});

export const { clearMessage } = orderSlice.actions;
export default orderSlice.reducer;
