import { createSlice } from "@reduxjs/toolkit";
import { getDistrictByCityID } from "./districtThunk";

const initialState = {
  cityRelatedDistrict: {
    data: {},
    loading: false,
    error: null,
    hasFetched: false,
  },
};

const districtSlice = createSlice({
  name: "cityRelatedDistrict",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDistrictByCityID.pending, (state) => {
        state.cityRelatedDistrict.loading = true;
        state.cityRelatedDistrict.error = null;
      })
      .addCase(getDistrictByCityID.fulfilled, (state, action) => {
        state.cityRelatedDistrict.loading = false;
        state.cityRelatedDistrict.data = action.payload;
        state.cityRelatedDistrict.hasFetched = true;
      })
      .addCase(getDistrictByCityID.rejected, (state, action) => {
        state.cityRelatedDistrict.loading = false;
        state.cityRelatedDistrict.error = action.error.message;
      });
  },
});

export default districtSlice.reducer;
