const getTitleFromPathname = (pathname) => {
  if (pathname === "/") {
    return "Home";
  }
  const words = pathname.slice(1).split("/");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const title = capitalizedWords.join(" ");
  return title;
};

export default getTitleFromPathname;
