import { createSlice } from "@reduxjs/toolkit";
import { getCityList } from "./cityThunk";

const initialState = {
  cityList: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
};

const citySlice = createSlice({
  name: "cityData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCityList.pending, (state) => {
        state.cityList.loading = true;
        state.cityList.error = null;
      })
      .addCase(getCityList.fulfilled, (state, action) => {
        state.cityList.loading = false;
        state.cityList.data = action.payload;
        state.cityList.hasFetched = true;
      })
      .addCase(getCityList.rejected, (state, action) => {
        state.cityList.loading = false;
        state.cityList.error = action.error.message;
      })
  },
});

export default citySlice.reducer;