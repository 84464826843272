import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import NavBar from "../components/navbar/NavBar";
import LoadingSpinner from "../components/loading-spinner/LoadingSpinner";
import getTitleFromPathname from "./titleMapper.jsx";
import "./MainLayout.css";

const MainLayout = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const location = useLocation();

  useEffect(() => {
    const title = getTitleFromPathname(location.pathname);
    document.title = `${title} - Ninety Seven`;
  }, [location.pathname]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <NavBar />
      <div className="outlet-body">
        <Outlet />
      </div>
    </>
  );
};

export default MainLayout;
