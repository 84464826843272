import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { startLoading, stopLoading } from "../loading/loadingSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const placeOrder = createAsyncThunk(
  "cart/placeOrder",
  async (orderData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(
        `${API_BASE_URL}/api/orders`,
        orderData
      );
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      return rejectWithValue(error.response.data);
    }
  }
);
