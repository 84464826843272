import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getShopByID } from "../../../redux/shop/shopsThunk";
import { getProductByShopID } from "../../../redux/product/productThunk";
import { getCategoryByShopID } from "../../../redux/category/categoryThunk";
import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

import "./ShopDetailPage.css";
import SideBar from "../../../components/side-bar/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const mainImagePath = `${API_BASE_URL}/images/shop_images/main_image/`;
const otherImagePath1 = `${API_BASE_URL}/images/shop_images/other_image/1/`;
const otherImagePath2 = `${API_BASE_URL}/images/shop_images/other_image/2/`;
const otherImagePath3 = `${API_BASE_URL}/images/shop_images/other_image/3/`;

const productMainImagePath = `${API_BASE_URL}/images/product_images/main_image/`;

const ShopDetailPage = () => {
  const { id: shopId } = useParams();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [availableImages, setAvailableImages] = useState([]);
  const [selectedShopId, setSelectedShopId] = useState(shopId);

  // start - on mobile phone screen side bar control
  const [isDisplaySideBar, setIsDisplaySideBar] = useState(
    window.innerWidth > 768
  );
  useEffect(() => {
    const handleResize = () => {
      setIsDisplaySideBar(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // end - on mobile phone screen side bar control

  const dispatch = useDispatch();
  const { shop } = useSelector((state) => state.shopData);
  const { shopRelatedProduct } = useSelector((state) => state.productData);
  const { shopRelatedCategory } = useSelector((state) => state.categoryData);

  const toggleSidebar = () => {
    setIsDisplaySideBar(!isDisplaySideBar);
  };

  useEffect(() => {
    if (
      selectedShopId &&
      (!shop.data || shop.data.id !== parseInt(selectedShopId))
    ) {
      dispatch(getShopByID(selectedShopId));
      dispatch(getProductByShopID(selectedShopId));
      dispatch(getCategoryByShopID(selectedShopId));
    }
  }, [dispatch, selectedShopId, shop.data]);

  useEffect(() => {
    if (shop.data && shop.data.id === parseInt(selectedShopId)) {
      const images = [{ path: mainImagePath, name: shop.data.main_image }];

      if (shop.data.other_image_01) {
        images.push({ path: otherImagePath1, name: shop.data.other_image_01 });
      }
      if (shop.data.other_image_02) {
        images.push({ path: otherImagePath2, name: shop.data.other_image_02 });
      }
      if (shop.data.other_image_03) {
        images.push({ path: otherImagePath3, name: shop.data.other_image_03 });
      }

      setAvailableImages(images);
      setCurrentImageIndex(0);
    }
  }, [shop.data, selectedShopId]);

  useEffect(() => {
    if (availableImages.length > 0) {
      const interval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % availableImages.length
        );
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [availableImages.length]);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const shopRelatedProductHandler = (id) => {};

  const [expandedCategories, setExpandedCategories] = useState([]);

  useEffect(() => {
    if (shopRelatedCategory.data) {
      setExpandedCategories(
        shopRelatedCategory.data.map((category) => category.id)
      );
    }
  }, [shopRelatedCategory]);

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  return (
    <div className="shop-detail-container">
      {isDisplaySideBar && (
        <SideBar
          selectedShopId={selectedShopId}
          onSelectShop={setSelectedShopId}
        />
      )}
      <button onClick={toggleSidebar} className="sidebar-toggle-button">
        <FontAwesomeIcon
          icon={isDisplaySideBar ? faChevronLeft : faChevronRight}
        />
      </button>
      <div className="shop-detail-content">
        {shop.data && shop.data.id === parseInt(selectedShopId) ? (
          <>
            <div className="shop-title">{shop.data.name}</div>
            <div>
              {availableImages.length > 0 ? (
                <div>
                  <div className="shop-image-slider-area">
                    <img
                      src={`${availableImages[currentImageIndex]?.path}${availableImages[currentImageIndex]?.name}`}
                      className="shop-image"
                      alt=""
                    />
                  </div>
                  <div className="dots-container-shop-detail">
                    {availableImages.map((_, index) => (
                      <span
                        key={index}
                        className={`dot ${
                          index === currentImageIndex ? "active" : ""
                        }`}
                        onClick={() => handleDotClick(index)}
                      ></span>
                    ))}
                  </div>
                </div>
              ) : (
                <div>No images available</div>
              )}
            </div>
            <br />
            <div className="shop-details">
              <p>
                <strong>Address:</strong> {shop.data.address}
              </p>
              <p>
                <strong>Open Time:</strong> {shop.data.open_time}
              </p>
              <p>
                <strong>Close Time:</strong> {shop.data.close_time}
              </p>
            </div>
            <br />
            <div>
              {shopRelatedCategory.data &&
              shopRelatedCategory.data.length > 0 ? (
                shopRelatedCategory.data.map((category, index) => {
                  const filteredProducts = shopRelatedProduct.data
                    ? shopRelatedProduct.data.filter(
                        (product) =>
                          parseInt(product.category_id, 10) === category.id
                      )
                    : [];

                  return filteredProducts.length > 0 ? (
                    <div key={index}>
                      <div className="category-heading">
                        <span>{category.name}</span>
                        <button
                          className="toggle-button"
                          onClick={() => toggleCategory(category.id)}
                        >
                          <FontAwesomeIcon
                            icon={
                              expandedCategories.includes(category.id)
                                ? faChevronUp
                                : faChevronDown
                            }
                          />
                        </button>
                      </div>
                      {expandedCategories.includes(category.id) && (
                        <div className="shop-related-products">
                          {filteredProducts.length > 0 ? (
                            filteredProducts.map((product, index) => (
                              <div
                                key={index}
                                className="product-item"
                                onClick={() =>
                                  shopRelatedProductHandler(product.id)
                                }
                              >
                                <div className="product-item-top">
                                  <div className="product-main-image-area">
                                    <Link
                                      to={`/shops/${shop.data.id}/product/${product.id}`}
                                    >
                                      <img
                                        className="product-main-image"
                                        src={`${productMainImagePath}${product.main_image}`}
                                        alt=""
                                      />
                                    </Link>
                                  </div>
                                  <div className="product-name-area">
                                    {product.name}
                                  </div>
                                  <div className="product-details-area">
                                    <span>Brand</span> -{" "}
                                    {product.brands && product.brands.name}
                                    <br />
                                    <span>Category</span> -{" "}
                                    {product.categories &&
                                      product.categories.name}
                                    <br />
                                    {product.explanatory_note &&
                                      product.explanatory_note}
                                  </div>
                                </div>
                                <div className="product-item-bottom">
                                  <div className="product-item-price">
                                    Rs - {product.price && product.price} /=
                                  </div>
                                  <div className="product-item-add-to-cart">
                                    <Link
                                      to={`/shops/${shop.data.id}/product/${product.id}`}
                                    >
                                      <button className="product-item-add-to-cart-btn">
                                        Add to cart
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="no-items-found">
                              No products found
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null;
                })
              ) : (
                <div></div>
              )}
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default ShopDetailPage;
