import { createSlice } from "@reduxjs/toolkit";
import { getCategoryByShopID } from "./categoryThunk";

const initialState = {
  shopRelatedCategory: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
};

const categorySlice = createSlice({
  name: "shopRelatedCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryByShopID.pending, (state) => {
        state.shopRelatedCategory.loading = true;
        state.shopRelatedCategory.error = null;
      })
      .addCase(getCategoryByShopID.fulfilled, (state, action) => {
        state.shopRelatedCategory.loading = false;
        state.shopRelatedCategory.data = action.payload;
        state.shopRelatedCategory.hasFetched = true;
      })
      .addCase(getCategoryByShopID.rejected, (state, action) => {
        state.shopRelatedCategory.loading = false;
        state.shopRelatedCategory.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
