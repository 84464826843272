import { createSlice } from "@reduxjs/toolkit";
import { getCountryList } from "./countryThunk";

const initialState = {
  countryList: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
};

const countrySlice = createSlice({
  name: "countryData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.pending, (state) => {
        state.countryList.loading = true;
        state.countryList.error = null;
      })
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.countryList.loading = false;
        state.countryList.data = action.payload;
        state.countryList.hasFetched = true;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.countryList.loading = false;
        state.countryList.error = action.error.message;
      });
  },
});

export default countrySlice.reducer;
