import { createSlice } from "@reduxjs/toolkit";
import { getProductByShopID, getProductByID,getProductAsSearchResults } from "./productThunk";

const initialState = {
  shopRelatedProduct: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
  product: {
    data: {},
    loading: false,
    error: null,
    hasFetched: false,
  },
  productSearchResults: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
};

const productSlice = createSlice({
  name: "shopRelatedProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductByShopID.pending, (state) => {
        state.shopRelatedProduct.loading = true;
        state.shopRelatedProduct.error = null;
      })
      .addCase(getProductByShopID.fulfilled, (state, action) => {
        state.shopRelatedProduct.loading = false;
        state.shopRelatedProduct.data = action.payload;
        state.shopRelatedProduct.hasFetched = true;
      })
      .addCase(getProductByShopID.rejected, (state, action) => {
        state.shopRelatedProduct.loading = false;
        state.shopRelatedProduct.error = action.error.message;
      })
      .addCase(getProductByID.pending, (state) => {
        state.product.loading = true;
        state.product.error = null;
      })
      .addCase(getProductByID.fulfilled, (state, action) => {
        state.product.loading = false;
        state.product.data = action.payload;
        state.product.hasFetched = true;
      })
      .addCase(getProductByID.rejected, (state, action) => {
        state.product.loading = false;
        state.product.error = action.error.message;
      })
      .addCase(getProductAsSearchResults.pending, (state) => {
        state.productSearchResults.loading = true;
        state.productSearchResults.error = null;
      })
      .addCase(getProductAsSearchResults.fulfilled, (state, action) => {
        state.productSearchResults.loading = false;
        state.productSearchResults.data = action.payload;
        state.productSearchResults.hasFetched = true;
      })
      .addCase(getProductAsSearchResults.rejected, (state, action) => {
        state.productSearchResults.loading = false;
        state.productSearchResults.error = action.error.message;
      })
  },
});

export default productSlice.reducer;
