import { createSlice } from "@reduxjs/toolkit";
import { login, logout, signupCustomer } from "./authThunk";

const NinetySevenAuth = localStorage.getItem("NinetySevenAuth");
const NinetySevenAuthObject = NinetySevenAuth && JSON.parse(NinetySevenAuth);
const user = NinetySevenAuthObject && NinetySevenAuthObject.user;
const token = NinetySevenAuthObject && NinetySevenAuthObject.token;

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: user || null,
    token: token || null,
    loading: false,
    error: null,
    message: null,
  },
  reducers: {
    clearMessage(state) {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        localStorage.setItem(
          "NinetySevenAuth",
          JSON.stringify({
            user: action.payload.user,
            token: action.payload.token,
          })
        );
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        localStorage.removeItem("NinetySevenAuth");
      })
      .addCase(signupCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.customer;
        state.message = "Customer Registered Successfully";
        // state.token = action.payload.token;
        localStorage.setItem(
          "NinetySevenAuth",
          JSON.stringify({
            user: action.payload.customer,
            // token: action.payload.token,
          })
        );
      })
      .addCase(signupCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { clearMessage } = authSlice.actions;
export default authSlice.reducer;
