import { useSelector } from 'react-redux';
import './MapBar.css';
import { Link } from 'react-router-dom';

const MapBar = () => {
  const steps = useSelector((state) => state.stepMap.steps);

  return (
    <div className="breadcrumb">
      {steps.map((step, index) => (
        <Link to={step.path} key={index} className="breadcrumb-link">
          <div className={`breadcrumb-item ${step.isPass ? 'home' : ''}`}>
            {step.icon && <span className="home-icon">{step.icon}</span>}
            {step.label}
          </div>
        </Link>
      ))}
    </div>
  );
}

export default MapBar;
