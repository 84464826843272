import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { startLoading, stopLoading } from "../loading/loadingSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { dispatch }) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(
        `${API_BASE_URL}/api/login`,
        credentials
      );
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    try {
      dispatch(startLoading());
      // const NinetySevenAuth = localStorage.getItem("NinetySevenAuth");
      // const NinetySevenAuthObject = JSON.parse(NinetySevenAuth);
      // const token = NinetySevenAuthObject.token;
      // const response = await axios.post(
      //   `${API_BASE_URL}/api/logout`,
      //   {},
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      localStorage.removeItem("NinetySevenAuth");
      dispatch(stopLoading());
      // return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const signupCustomer = createAsyncThunk(
  "auth/signupCustomer",
  async (customerData, { dispatch }) => {
    try {
      dispatch(startLoading());
      const response = await axios.post(
        `${API_BASE_URL}/api/customer`,
        customerData
      );
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);


