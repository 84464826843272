import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  incrementItemQuantity,
  decrementItemQuantity,
  removeItemFromCart,
  clearCart,
} from "../../redux/cart/cartSlice";
import "./Cart.css";
import { signupCustomer } from "../../redux/auth/authThunk";
import { getCityList } from "../../redux/city/cityThunk";
import { getCountryList } from "../../redux/country/countryThunk";
import { getDistrictByCityID } from "../../redux/district/districtThunk";
import { clearMessage } from "../../redux/auth/authSlice";
import MessageBox from "../../components/message-box/MessageBox";
import { placeOrder } from "../../redux/cart/cartThunk";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);
  const { countryList } = useSelector((state) => state.countryData);
  const { cityList } = useSelector((state) => state.cityData);
  const { cityRelatedDistrict } = useSelector((state) => state.districtData);
  const { message } = useSelector((state) => state.auth);

  const [customerName, setCustomerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [location, setLocation] = useState(null);

  const [stepCount, setStepCount] = useState(1);

  const [recipientName, setRecipientName] = useState("");
  const [recipientPhoneNumber, setRecipientPhoneNumber] = useState("");
  const [recipientPlace, setRecipientPlace] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [district, setDistrict] = useState({
    districtID: "",
    districtName: "",
  });
  const [province, setProvince] = useState({
    provinceID: "",
    provinceName: "",
  });
  const [recipientRemark, setRecipientRemark] = useState(null);

  const countryOptions = countryList.data.map((country) => ({
    value: country.id,
    label: `${country.code} - ${country.name}`,
  }));

  const cityOptions = cityList.data.map((city) => ({
    value: city.id,
    label: `${city.name_en}`,
  }));

  const handleIncrement = (id, shop_id) => {
    dispatch(incrementItemQuantity({ id, shop_id }));
  };

  const handleDecrement = (id, shop_id) => {
    dispatch(decrementItemQuantity({ id, shop_id }));
  };

  const handleRemove = (id, shop_id) => {
    dispatch(removeItemFromCart({ id, shop_id }));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const handleBackButton = () => {
    setStepCount(stepCount - 1);
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && (value === "" || value[0] !== "0")) {
      setPhoneNumber(value);
    }
  };

  const handleDeliverInfoRegister = () => {
    const DiliverData = {
      items: cart.items,
      totalQuantity: cart.totalQuantity,
      totalAmount: cart.totalAmount,
      RecipientName: recipientName,
      RecipientPhoneNumber: recipientPhoneNumber,
      RecipientPlace: recipientPlace,
      City: selectedCity.value,
      District: district.districtID,
      Province: province.provinceID,
      RecipientRemark: province.provinceID,
      CustomerID: user.id,
      DeliveryStatus: 1,
    };

    if (
      !recipientName ||
      !recipientPhoneNumber ||
      !recipientPlace ||
      !recipientRemark
    ) {
      alert("All customer information fields are required.");
      return;
    }
    dispatch(placeOrder(DiliverData));

    setStepCount(stepCount + 1);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    dispatch(getDistrictByCityID(selectedOption.value));
  };

  const handleSelectPlace = () => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const { latitude, longitude } = position.coords;
    //       const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    //       window.open(mapsUrl, "_blank");
    //     },
    //     (error) => {
    //       console.error("Error getting the user's location: ", error);
    //       alert("Unable to retrieve your location.");
    //     }
    //   );
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          console.log("Current Location:", { latitude, longitude });
        },
        (error) => {
          console.error("Error fetching location:", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const defaultIcon = L.icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
  });

  useEffect(() => {
    if (
      cityRelatedDistrict.data &&
      typeof cityRelatedDistrict.data === "object"
    ) {
      setDistrict({
        districtID: cityRelatedDistrict.data.id,
        districtName: cityRelatedDistrict.data.name_en,
      });
      setProvince({
        provinceID: cityRelatedDistrict.data.province_id,
        provinceName: cityRelatedDistrict.data.province_name,
      });
    }
  }, [cityRelatedDistrict]);

  // const handlePlaceOrder = () => {
  //   if (!firstName || !lastName || !phoneNumber || !remarks) {
  //     alert("All customer information fields are required.");
  //     return;
  //   }
  //   const orderData = {
  //     items: cart.items,
  //     totalQuantity: cart.totalQuantity,
  //     totalAmount: cart.totalAmount,
  //     // first_name: firstName,
  //     // last_name: lastName,
  //     phone_number: phoneNumber,
  //     remarks: remarks,
  //   };
  //   dispatch(placeOrder(orderData));
  // };

  // start - customer register
  const handlePhoneNumberRegister = () => {
    const customerData = {
      countryCode: selectedCountry.value,
      phoneNumber: phoneNumber,
      name: customerName,
    };
    dispatch(signupCustomer(customerData));
    setStepCount(stepCount + 1);
  };

  const handleReviewOderButton = () => {
    navigate('/ongoing-orders')
  }

  useEffect(() => {
    if (user) {
      setSelectedCountry({
        label: "+94 - Sri lanka",
        value: user.country_id || null,
      });
      setPhoneNumber(user.phone_number || null);
      setCustomerName(user.name || "");
    }
  }, [user]);

  // end - customer register

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getCityList());
  }, [dispatch]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        dispatch(clearMessage());
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  return (
    <div className="cart-page-container">
      <MessageBox message={message} type="success" />
      <div className="cart-page-header-container">
        <div className="cart-page-left-container">
          {cart.items.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <>
              <div className="cart-summary-mob">
                <p>Total Quantity: {cart.totalQuantity}</p>
                <p>Total Amount: Rs {cart.totalAmount.toFixed(2)}</p>
                <button onClick={handleClearCart} className="clear-cart-button">
                  Clear Cart
                </button>
              </div>
              <div className="cart-items-container">
                {cart.items.map((item) => (
                  <div key={item.id} className="cart-item-container">
                    <div className="cart-item-header-container">
                      <h3 className="cart-item-product-name">
                        {item.product.name}
                      </h3>
                      <button
                        onClick={() => handleRemove(item.id, item.shop_id)}
                        className="cart-item-remove-button"
                      >
                        X
                      </button>
                    </div>
                    <div className="cart-item">
                      <div className="cart-item-main-image-container">
                        <img
                          src={`${API_BASE_URL}/images/product_images/main_image/${item.product.main_image}`}
                          alt=""
                          className="cart-item-main-image"
                        />
                      </div>
                      <div className="cart-item-details">
                        <p>Price: Rs {item.price}</p>
                        <p>Quantity: {item.quantity}</p>
                        <p>Total: Rs {item.totalPrice}</p>
                      </div>
                      <div className="cart-item-actions">
                        <button
                          onClick={() => handleIncrement(item.id, item.shop_id)}
                          className="cart-item-increment-button"
                        >
                          +
                        </button>
                        <button
                          onClick={() => handleDecrement(item.id, item.shop_id)}
                          className="cart-item-decrement-button"
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {cart.orderStatus === "loading" && <p>Placing order...</p>}
              {cart.orderStatus === "succeeded" && (
                <p>Order placed successfully!</p>
              )}
              {cart.orderStatus === "failed" && (
                <p>Error placing order: {cart.orderError}</p>
              )}
            </>
          )}
        </div>
        <div className="cart-page-right-container">
          <div className="cart-summary">
            <h2 className="cart-summary-title">Cart Summary</h2>
            <p>Total Quantity: {cart.totalQuantity}</p>
            <p>Total Amount: Rs {cart.totalAmount.toFixed(2)}</p>
            <button onClick={handleClearCart} className="clear-cart-button">
              Clear Cart
            </button>
          </div>
          {stepCount === 1 && (
            <>
              <div className="customer-info">
                <h2>Customer Register</h2>
                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Phone number
                  </label>
                  <div className="country-code-and-phone-number-container">
                    <Select
                      value={selectedCountry}
                      onChange={setSelectedCountry}
                      options={countryOptions}
                      className="cart-item-customer-info-select"
                      placeholder="Select Country"
                      isSearchable
                      required
                    />
                    <input
                      type="text"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      className="cart-item-customer-info-text-input cart-item-customer-info-text-input-phone-number"
                      required
                    />
                  </div>
                </div>
                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">Name</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    className="cart-item-customer-info-text-input"
                    required
                  />
                </div>
              </div>
              <div className="cart-place-order-button-container">
                <button
                  onClick={handlePhoneNumberRegister}
                  className="cart-place-order-button"
                >
                  Next
                </button>
              </div>
            </>
          )}

          {stepCount === 2 && (
            <>
              <div className="customer-info">
                <h2>Deliver Information</h2>
                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Recipient's Name
                  </label>
                  <input
                    type="text"
                    placeholder="Recipient's Name"
                    value={recipientName}
                    onChange={(e) => setRecipientName(e.target.value)}
                    className="cart-item-customer-info-text-input"
                    required
                  />
                </div>
                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Recipient's Phone number
                  </label>
                  <input
                    type="text"
                    placeholder="Recipient's Phone number"
                    value={recipientPhoneNumber}
                    onChange={(e) => setRecipientPhoneNumber(e.target.value)}
                    className="cart-item-customer-info-text-input"
                    required
                  />
                </div>
                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Recipient's Place
                  </label>
                  <input
                    type="text"
                    placeholder="Recipient's Place"
                    value={recipientPlace}
                    onChange={(e) => setRecipientPlace(e.target.value)}
                    className="cart-item-customer-info-text-input"
                    required
                  />
                </div>
                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Choose place on google map
                  </label>
                  <button onClick={handleSelectPlace}>Select place</button>

                  {location && (
                    <div>
                      <p>
                        Selected Location: Latitude: {location.latitude},
                        Longitude: {location.longitude}
                      </p>
                      <MapContainer
                        center={[location.latitude, location.longitude]}
                        zoom={13}
                        style={{ height: "400px", width: "100%" }}
                      >
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker
                          position={[location.latitude, location.longitude]}
                          icon={defaultIcon}
                        >
                          <Popup>
                            You are here: {location.latitude},{" "}
                            {location.longitude}
                          </Popup>
                        </Marker>
                      </MapContainer>
                    </div>
                  )}
                </div>

                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Select City
                  </label>
                  <Select
                    value={selectedCity}
                    onChange={handleCityChange}
                    options={cityOptions}
                    className="cart-item-customer-info-select"
                    placeholder="Select City"
                    isSearchable
                    required
                  />
                </div>

                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    District
                  </label>
                  <input
                    type="text"
                    placeholder="District"
                    value={district.districtName}
                    className="cart-item-customer-info-text-input"
                    readOnly
                  />
                </div>

                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Province
                  </label>
                  <input
                    type="text"
                    placeholder="Province"
                    value={province.provinceName}
                    className="cart-item-customer-info-text-input"
                    readOnly
                  />
                </div>

                <div className="cart-item-customer-info-field">
                  <label className="cart-item-customer-info-label">
                    Recipient's Remark
                  </label>
                  <input
                    type="text"
                    placeholder="Recipient's Remark"
                    value={recipientRemark}
                    onChange={(e) => setRecipientRemark(e.target.value)}
                    className="cart-item-customer-info-text-input"
                    required
                  />
                </div>
              </div>
              <div className="cart-place-order-button-container">
                <button
                  onClick={() => handleBackButton(phoneNumber)}
                  className="cart-place-order-button"
                >
                  Back
                </button>
                <button
                  onClick={handleDeliverInfoRegister}
                  className="cart-place-order-button"
                >
                  Next
                </button>
              </div>
            </>
          )}

          {stepCount === 3 && (
            <>
              <div className="customer-info">
                <h2>Your order has successfully completed</h2>

                <button
                  onClick={() => handleReviewOderButton()}
                  className="cart-review-order-button"
                >
                  Review order
                </button>
              </div>

              <div className="cart-place-order-button-container">
                <button
                  onClick={() => handleBackButton(phoneNumber)}
                  className="cart-place-order-button"
                >
                  Back
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
