import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { getProductByID } from "../../../redux/product/productThunk";
import { addItemToCart } from "../../../redux/cart/cartSlice";

import "./ProductDetail.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ProductDetail = () => {
  const { ProductID, ShopID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [count, setCount] = useState(1);
  const [mainImageUrl, setMainImageUrl] = useState("");
  const [otherImages, setOtherImages] = useState([]);

  const { product } = useSelector((state) => state.productData);

  const MySwal = withReactContent(Swal);

  const handleAddToCart = () => {
    const isShopCurrentlyOpen = isShopOpen(
      product.data.shops?.open_time,
      product.data.shops?.close_time
    );

    if (!isShopCurrentlyOpen) {
      MySwal.fire({
        title: "Shop Closed",
        text: "This shop is closed now. Please order from another shop.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      MySwal.fire({
        title: "Add this item to the cart?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, add it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(addItemToCart({ ...product.data, quantity: count }));
          MySwal.fire(
            "Added!",
            "The item has been added to your cart.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          MySwal.fire("Cancelled", "The item was not added.", "error");
        }
      });
    }
  };

  useEffect(() => {
    dispatch(getProductByID(ProductID));
  }, [dispatch, ProductID]);

  useEffect(() => {
    if (product.data) {
      const mainImage = `${API_BASE_URL}/images/product_images/main_image/${product.data.main_image}`;
      setMainImageUrl(mainImage);

      const otherImagesList = Array.from({ length: 9 }, (_, i) => {
        const imageKey = `other_image_0${i + 1}`;
        if (product.data[imageKey]) {
          return {
            id: i + 1,
            url: `${API_BASE_URL}/images/product_images/other_image/${i + 1}/${
              product.data[imageKey]
            }`,
          };
        }
        return null;
      }).filter((image) => image !== null);

      setOtherImages([{ id: 0, url: mainImage }, ...otherImagesList]);
    }
  }, [product]);

  const increment = () => setCount((prevCount) => prevCount + 1);
  const decrement = () =>
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));

  const handleImageClick = (imageUrl) => {
    setMainImageUrl(imageUrl);
  };

  const handleClose = () => {
    navigate(`/shops/${ShopID}`);
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const isShopOpen = (openTime, closeTime) => {
    const current = currentTime;
    const open = new Date(`${current.toDateString()} ${openTime}`);
    const close = new Date(`${current.toDateString()} ${closeTime}`);

    if (close < open) {
      if (current >= open || current <= close) {
        return true;
      }
    } else if (current >= open && current <= close) {
      return true;
    }

    return false;
  };

  return (
    <div className="product-detail-container">
      {product.data ? (
        <>
          <div className="product-detail-left-container">
            <div className="product-detail-name">{product.data.name}</div>
            <div className="product-detail-main-image-container">
              <img
                src={mainImageUrl}
                alt={product.data.name}
                className="product-detail-main-image"
              />
            </div>
            <div className="product-detail-price-container">
              Price - Rs {product.data.price}
            </div>
            <div className="product-detail-product-count-picker-container">
              <input
                type="text"
                value={count}
                readOnly
                className="product-detail-count-input"
              />
              <button
                onClick={increment}
                className="product-detail-increment-button"
              >
                &#9650;
              </button>
              <button
                onClick={decrement}
                className="product-detail-decrement-button"
              >
                &#9660;
              </button>
            </div>
            <div className="product-detail-add-to-cart-button-container">
              <button
                onClick={handleAddToCart}
                className="product-detail-add-to-cart-button"
              >
                Add to Cart
              </button>
            </div>
          </div>
          <div className="product-detail-right-container">
            <div className="product-detail-shop-name-container">
              Shop - {product.data.shops && product.data.shops.name}
            </div>
            <div className="product-detail-category-name-container">
              Category -{" "}
              {product.data.categories && product.data.categories.name}
            </div>
            <div className="product-detail-category-name-container">
              Brand - {product.data.brands && product.data.brands.name}
            </div>
            <div className="product-detail-explanatory-note-container">
              {product.data.explanatory_note}
            </div>

            <div className="product-detail-explanatory-note-container">
              <span>Status:</span>{" "}
              <span
                style={{
                  color: isShopOpen(
                    product.data.shops?.open_time,
                    product.data.shops?.close_time
                  )
                    ? "green"
                    : "red",
                  fontWeight: "bold",
                }}
              >
                {isShopOpen(
                  product.data.shops?.open_time,
                  product.data.shops?.close_time
                )
                  ? "Shop Open"
                  : "Shop Closed"}
              </span>
            </div>

            <div className="product-detail-other-images-container">
              {otherImages.map((image) => (
                <div
                  className="product-detail-other-image-container"
                  key={image.id}
                >
                  <img
                    src={image.url}
                    alt={product.data.name}
                    className="product-detail-other-image"
                    onClick={() => handleImageClick(image.url)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div>
            <button className="product-detail-close-btn" onClick={handleClose}>
              X
            </button>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default ProductDetail;
