import { createSlice } from "@reduxjs/toolkit";
import { getShops, getShopByID } from "./shopsThunk";

const initialState = {
  shops: {
    data: [],
    loading: false,
    error: null,
    hasFetched: false,
  },
  shop: {
    data: {},
    loading: false,
    error: null,
    hasFetched: false,
  },
};

const shopsSlice = createSlice({
  name: "shopData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShops.pending, (state) => {
        state.shops.loading = true;
        state.shops.error = null;
      })
      .addCase(getShops.fulfilled, (state, action) => {
        state.shops.loading = false;
        state.shops.data = action.payload;
        state.shops.hasFetched = true;
      })
      .addCase(getShops.rejected, (state, action) => {
        state.shops.loading = false;
        state.shops.error = action.error.message;
      })
      .addCase(getShopByID.pending, (state) => {
        state.shop.loading = true;
        state.shop.error = null;
      })
      .addCase(getShopByID.fulfilled, (state, action) => {
        state.shop.loading = false;
        state.shop.data = action.payload;
        state.shop.hasFetched = true;
      })
      .addCase(getShopByID.rejected, (state, action) => {
        state.shop.loading = false;
        state.shop.error = action.error.message;
      });
  },
});

export default shopsSlice.reducer;
