import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { startLoading, stopLoading } from "../loading/loadingSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getCompletedOrdersByUserID = createAsyncThunk(
  "get/CompletedOrdersByUserID",
  async (userID, { dispatch }) => {
    try {
      dispatch(startLoading());
      const NinetySevenAuth = localStorage.getItem("NinetySevenAuth");
      const NinetySevenAuthObject = JSON.parse(NinetySevenAuth);
      const token = NinetySevenAuthObject.token;
      const response = await axios.get(
        `${API_BASE_URL}/api/completed/order/deatils/${userID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const getOngoingOrdersByUserID = createAsyncThunk(
  "get/OngoingOrdersByUserID",
  async (userID, { dispatch }) => {
    try {
      dispatch(startLoading());
      const NinetySevenAuth = localStorage.getItem("NinetySevenAuth");
      const NinetySevenAuthObject = JSON.parse(NinetySevenAuth);
      const token = NinetySevenAuthObject.token;
      const response = await axios.get(
        `${API_BASE_URL}/api/ongoing/order/deatils/${userID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const getOrderByID = createAsyncThunk(
  "get/OrderByID",
  async (OrderID, { dispatch }) => {
    try {
      dispatch(startLoading());
      const NinetySevenAuth = localStorage.getItem("NinetySevenAuth");
      const NinetySevenAuthObject = JSON.parse(NinetySevenAuth);
      const token = NinetySevenAuthObject.token;
      const response = await axios.get(`${API_BASE_URL}/api/order/${OrderID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const deleteOrderHistory = createAsyncThunk(
  "order/deleteOrderHistory",
  async (orderIds, { dispatch }) => {
    try {
      dispatch(startLoading());
      const NinetySevenAuth = localStorage.getItem("NinetySevenAuth");
      const NinetySevenAuthObject = JSON.parse(NinetySevenAuth);
      const token = NinetySevenAuthObject.token;
      const response = await axios.post(
        `${API_BASE_URL}/api/order/delete-history`,
        { orderIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(stopLoading());
      dispatch(getCompletedOrdersByUserID(NinetySevenAuthObject.user.id));
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);
