import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { setSteps } from "../../../../redux/step-map/stepMapSlice";
import { getOngoingOrdersByUserID } from "../../../../redux/order/orderThunk";
import { getDeliveryStatus } from "../../../../redux/delivery-status/deliveryStatusThunk";
import MapBar from "../../../../components/map-bar/MapBar";
import ProgressBar from "../../../../components/progress-bar/ProgressBar";
import "./OngoingOrder.css";

const OngoingOrder = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { OngoingOrders } = useSelector((state) => state.orderData);
  const { deliveryStatusList } = useSelector(
    (state) => state.deliveryStatusData
  );

  const [orderDetailsVisibility, setOrderDetailsVisibility] = useState({});

  const toggleOrderDetails = (orderId) => {
    setOrderDetailsVisibility((prevState) => ({
      ...prevState,
      [orderId]: !prevState[orderId],
    }));
  };
  useEffect(() => {
    dispatch(
      setSteps([
        { label: "HOME", path: "/", icon: "🏠", isPass: true },
        { label: "MY ACCOUNT", path: "/my-account", isPass: true },
        { label: "ONGOING ORDERS", path: "/ongoing-orders", isPass: false },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOngoingOrdersByUserID(user.id));
    dispatch(getDeliveryStatus());
  }, [dispatch, user.id]);

  return (
    <div className="ongoing-order-page-container">
      <MapBar />
      <div className="ongoing-order-user-full-name-container">
        {user && `${user.name}`}
      </div>
      <div className="ongoing-order-view-area-container">
        {OngoingOrders &&
        OngoingOrders.data &&
        OngoingOrders.data.length > 0 ? (
          OngoingOrders.data.map((order) => (
            <div key={order.id} className="order-container">
              <div className="order-header-container">
                <div>
                  <p>Order Id: {order.id}</p>
                  <p>Date Time: {order.date_time}</p>
                </div>
                <div>
                  <button
                    onClick={() => toggleOrderDetails(order.id)}
                    className="icon-button"
                  >
                    {orderDetailsVisibility[order.id] ? (
                      <FontAwesomeIcon icon={faChevronDown} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronUp} />
                    )}
                  </button>
                </div>
              </div>
              {deliveryStatusList &&
              deliveryStatusList.data &&
              deliveryStatusList.data.length > 0 ? (
                <>
                  <ProgressBar
                    order={order}
                    deliveryStatusList={deliveryStatusList}
                  />
                  {orderDetailsVisibility[order.id] && (
                    <div className="order-details">
                      <div className="order-detail-item">
                        <span className="order-detail-label">Full Name:</span>{" "}
                        {order.first_name} {order.last_name}
                      </div>
                      <div className="order-detail-item">
                        <span className="order-detail-label">Remarks:</span>{" "}
                        {order.remarks}
                      </div>
                      <div className="order-detail-item">
                        <span className="order-detail-label">
                          Phone Number:
                        </span>{" "}
                        {order.phone_number}
                      </div>
                      <div className="order-detail-item">
                        <span className="order-detail-label">
                          Number of Items:
                        </span>{" "}
                        {order.no_of_item}
                      </div>
                      <div className="order-detail-item">
                        <span className="order-detail-label">
                          Wrapping Charges:
                        </span>{" "}
                        {order.wrapping_charges}
                      </div>
                      <div className="order-detail-item">
                        <span className="order-detail-label">
                          Delivery Charges:
                        </span>{" "}
                        {order.delivery_charges}
                      </div>
                      <div className="order-detail-item">
                        <span className="order-detail-label">
                          Total Amount:
                        </span>{" "}
                        {order.total_amount}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div>No delivery status</div>
              )}
            </div>
          ))
        ) : (
          <div>No ongoing orders.</div>
        )}
      </div>
    </div>
  );
};

export default OngoingOrder;
