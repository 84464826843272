import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ order, deliveryStatusList }) => {
  let completed = true;
  return (
    <div className="stepper-wrapper">
      {deliveryStatusList.data.slice(0, -1).map((status, index) => {
        if (completed && parseInt(order.delivery_status_id, 10) === status.id) {
          completed = false;
        }
        return (
          <div
            key={status.id}
            className={`stepper-item ${completed ? "completed" : ""} ${
              parseInt(order.delivery_status_id, 10) === status.id ? "active" : ""
            }`}
          >
            <div className="step-counter">{index + 1}</div>
            <div className="step-name">{status.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
