import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { signupCustomer } from "../../redux/auth/authThunk";
import { getCountryList } from "../../redux/country/countryThunk";

import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, user } = useSelector((state) => state.auth);
  const { countryList } = useSelector((state) => state.countryData);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const countryOptions = countryList.data.map((country) => ({
    value: country.id,
    label: `${country.code} - ${country.name}`,
  }));

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && (value === "" || value[0] !== "0")) {
      setPhoneNumber(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const customerData = {
      countryCode: selectedCountry.value,
      phoneNumber: phoneNumber,
      name: name,
    };
    dispatch(signupCustomer(customerData));
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    dispatch(getCountryList());
  }, [dispatch]);

  return (
    <div className="login-container">
      <div className="login-form header">
        <h1>Ninety Seven</h1>
      </div>
      <div className="login-form">
        <form onSubmit={handleSubmit}>
          <div className="country-code-and-phone-number">
            <div>
              <label htmlFor="country_code">Country code</label>
              <Select
                value={selectedCountry}
                onChange={setSelectedCountry}
                options={countryOptions}
                className="cart-item-customer-info-select-login"
                placeholder="Select Country"
                isSearchable
                required
              />
            </div>
            <div>
              <label htmlFor="phone_number">Your Phone Number</label>
              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <button type="submit" disabled={loading}>
              Login
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
