import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Home from "../pages/home/Home";
import { UserProtected } from "./UserProtected";
import Login from "../pages/login/Login";
import ProductDetail from "../pages/product/productDetail/ProductDetail";
import Cart from "../pages/cart/Cart";
import MyAccount from "../pages/my-account/MyAccount";
import CompletedOrder from "../pages/my-account/order-history/completed-order/CompletedOrder";
import OngoingOrder from "../pages/my-account/order-history/ongoing-order/OngoingOrder";
import ShopPage from "../pages/shop/shopPage/ShopPage";
import ShopDetailPage from "../pages/shop/shopDetailPage/ShopDetailPage";
import ProductList from "../pages/product/productList/ProductList";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/shops",
        element: <ShopPage />,
      },
      {
        path: "/shops/:id",
        element: <ShopDetailPage />,
      },
      {
        path: "/shops/:ShopID/product/:ProductID",
        element: <ProductDetail />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/search-product",
        element: <ProductList />,
      },
      {
        element: <UserProtected />,
        children: [
          {
            path: "/my-account",
            element: <MyAccount />,
          },
          {
            path: "/completed-orders",
            element: <CompletedOrder />,
          },
          {
            path: "/ongoing-orders",
            element: <OngoingOrder />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
