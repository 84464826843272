import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faCheckCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";

import MapBar from "../../components/map-bar/MapBar";
import "./MyAccount.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { setSteps } from "../../redux/step-map/stepMapSlice";

const MyAccount = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(
      setSteps([
        { label: "HOME", path: "/", icon: "🏠", isPass: true },
        { label: "MY ACCOUNT", path: "/my-account", isPass: false },
      ])
    );
  }, [dispatch]);
  return (
    <div className="my-account-page-container">
      <MapBar />
      <div className="user-full-name-container">
        {user && `${user.name}`}
      </div>
      <div className="order-and-account-area-container">
        <div className="container">
          <div className="title">Order History</div>
          <div className="item-container">
            <Link to="/ongoing-orders" className="link-item">
              <div className="item">
                <FontAwesomeIcon icon={faTruck} /> Ongoing Orders
              </div>
            </Link>
            <Link to="/completed-orders" className="link-item">
              <div className="item">
                <FontAwesomeIcon icon={faCheckCircle} /> Completed Orders
              </div>
            </Link>
            <Link to="/shops" className="link-item">
              <div className="item">
                <FontAwesomeIcon icon={faPlusCircle} /> Place New Order
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
