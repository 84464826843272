import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import loadingReducer from "./loading/loadingSlice";
import shopsReducer from "./shop/shopsSlice";
import productReducer from "./product/productSlice";
import cartReducer from "./cart/cartSlice";
import stepMapReducer from "./step-map/stepMapSlice";
import orderReducer from "./order/orderSlice";
import deliveryStatusReducer from "./delivery-status/deliveryStatusSlice";
import cityReducer from "./city/citySlice";
import districtReducer from "./district/districtSlice";
import countryReducer from "./country/countrySlice";
import categoryReducer from "./category/categorySlice";

const store = configureStore({
  reducer: {
    loading: loadingReducer,
    auth: authReducer,
    shopData: shopsReducer,
    productData: productReducer,
    cart: cartReducer,
    stepMap: stepMapReducer,
    orderData: orderReducer,
    deliveryStatusData: deliveryStatusReducer,
    cityData: cityReducer,
    districtData: districtReducer,
    countryData: countryReducer,
    categoryData: categoryReducer,
  },
});

export default store;
