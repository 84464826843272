import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const productMainImagePath = `${API_BASE_URL}/images/product_images/main_image/`;

const ProductList = () => {
  const { shop } = useSelector((state) => state.shopData);
  const { productSearchResults } = useSelector((state) => state.productData);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer); 
  }, []);

  const isShopOpen = (openTime, closeTime) => {
    const current = currentTime;
    const open = new Date(`${current.toDateString()} ${openTime}`);
    const close = new Date(`${current.toDateString()} ${closeTime}`);

    if (close < open) {
      if (current >= open || current <= close) {
        return true;
      }
    } else if (current >= open && current <= close) {
      return true;
    }

    return false;
  };

  return (
    <div className="shop-related-products" style={{ padding: "10px 20px" }}>
      {productSearchResults.data && productSearchResults.data.length > 0 ? (
        productSearchResults.data.map((product, index) => {
          const shopOpenStatus = isShopOpen(
            product.shops?.open_time,
            product.shops?.close_time
          );

          return (
            <div key={index} className="product-item">
              <div className="product-item-top">
                <div className="product-main-image-area">
                  <Link to={`/shops/${shop.data.id}/product/${product.id}`}>
                    <img
                      className="product-main-image"
                      src={`${productMainImagePath}${product.main_image}`}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="product-name-area">{product.name}</div>
                <div className="product-details-area">
                  <span>Brand</span> - {product.brands && product.brands.name}{" "}
                  <br />
                  <span>Category</span> -{" "}
                  {product.categories && product.categories.name} <br />
                  {product.explanatory_note && product.explanatory_note}
                  <br />
                  <span>Shop Name</span> - {product.shops && product.shops.name}
                  <br />
                  <span>Opening Time</span> -{" "}
                  {product.shops && product.shops.open_time}
                  <br />
                  <span>Closing Time</span> -{" "}
                  {product.shops && product.shops.close_time}
                  <br />
                  <span
                    style={{
                      color: shopOpenStatus ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {shopOpenStatus ? "Shop Open" : "Shop Closed"}
                  </span>
                </div>
              </div>
              <div className="product-item-bottom">
                <div className="product-item-price">
                  Rs - {product.price && product.price} /=
                </div>
                <div className="product-item-add-to-cart">
                  <Link to={`/shops/${shop.data.id}/product/${product.id}`}>
                    <button className="product-item-add-to-cart-btn">
                      Add to cart
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-items-found">No products found</div>
      )}
    </div>
  );
};

export default ProductList;
