import { useEffect, useState } from "react";
import "./CompletedOrder.css";
import { useDispatch, useSelector } from "react-redux";
import { setSteps } from "../../../../redux/step-map/stepMapSlice";
import MapBar from "../../../../components/map-bar/MapBar";
import {
  deleteOrderHistory,
  getCompletedOrdersByUserID,
  getOrderByID,
} from "../../../../redux/order/orderThunk";
import MessageBox from "../../../../components/message-box/MessageBox";
import { clearMessage } from "../../../../redux/order/orderSlice";

const CompletedOrder = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { CompletedOrders, order, message } = useSelector(
    (state) => state.orderData
  );

  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [filterText, setFilterText] = useState("");

  const orderDetailHandler = (orderId) => () => {
    dispatch(getOrderByID(orderId));
  };

  const handleCheckboxChange = (orderId) => (event) => {
    if (event.target.checked) {
      setSelectedOrderIds((prev) => [...prev, orderId]);
    } else {
      setSelectedOrderIds((prev) => prev.filter((id) => id !== orderId));
    }
  };

  const handleDeleteHistory = () => {
    dispatch(deleteOrderHistory(selectedOrderIds));
  };

  useEffect(() => {
    dispatch(
      setSteps([
        { label: "HOME", path: "/", icon: "🏠", isPass: true },
        { label: "MY ACCOUNT", path: "/my-account", isPass: true },
        { label: "COMPLETED ORDERS", path: "/completed-orders", isPass: false },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCompletedOrdersByUserID(user.id));
  }, [dispatch, user.id]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        dispatch(clearMessage());
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  const filteredOrders =
    CompletedOrders?.data?.filter((order) =>
      order.date_time.toLowerCase().includes(filterText.toLowerCase())
    ) || [];

  return (
    <div className="completed-order-page-container">
      <MapBar />
      <div className="completed-order-user-full-name-container">
        {user && `${user.name}`}
      </div>
      <MessageBox message={message} type="success" />
      <div className="completed-order-view-area-container">
        <div className="completed-order-date-container">
          <input
            type="text"
            className="completed-order-filter"
            placeholder="Filter by date"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <div className="completed-order-date-list">
            {filteredOrders.length > 0 ? (
              filteredOrders.map((order) => (
                <div className="completed-order-date-item" key={order.id}>
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange(order.id)}
                  />
                  <div key={order.id} onClick={orderDetailHandler(order.id)}>
                    <p className="completed-order-date">{order.date_time}</p>
                  </div>
                </div>
              ))
            ) : (
              <div>No completed orders found.</div>
            )}
          </div>
          <div className="completed-order-delete-button-area">
            <button onClick={handleDeleteHistory}>Delete History</button>
          </div>
        </div>
        <div className="completed-order-detail-container">
          {order && order.data && Object.keys(order.data).length > 0 ? (
            <div>
              <div className="completed-order-detail-item">
                <span className="completed-order-detail-label">Full Name:</span>{" "}
                {order.data.first_name} {order.data.last_name}
              </div>
              <div className="completed-order-detail-item">
                <span className="order-detail-label">Remarks:</span>{" "}
                {order.data.remarks}
              </div>
              <div className="completed-order-detail-item">
                <span className="completed-order-detail-label">
                  Phone Number:
                </span>{" "}
                {order.data.phone_number}
              </div>
              <div className="completed-order-detail-item">
                <span className="completed-order-detail-label">
                  Number of Items:
                </span>{" "}
                {order.data.no_of_item}
              </div>
              <div className="completed-order-detail-item">
                <span className="completed-order-detail-label">
                  Wrapping Charges:
                </span>{" "}
                {order.data.wrapping_charges}
              </div>
              <div className="completed-order-detail-item">
                <span className="completed-order-detail-label">
                  Delivery Charges:
                </span>{" "}
                {order.data.delivery_charges}
              </div>
              <div className="completed-order-detail-item">
                <span className="completed-order-detail-label">
                  Total Amount:
                </span>{" "}
                {order.data.total_amount}
              </div>
            </div>
          ) : (
            <div>Not selected</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompletedOrder;
