import React, { useState, useEffect } from "react";
import topImage1 from "../../images/top-page-images/01.jpg";
import topImage2 from "../../images/top-page-images/02.jpg";
import topImage3 from "../../images/top-page-images/03.jpg";
import "./Home.css";

const images = [topImage1, topImage2, topImage3];

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div>
      <section className="main-section">
        <div className="tag-line-text">Welcome to Ninety Seven</div>
        <div className="sub-line-text">
          Ella is a small town in the Badulla District of Uva Province, Sri
          Lanka governed by an Urban Council.
        </div>
        <img
          src={images[currentImageIndex]}
          alt="Ninety seven top images"
          className="top-image"
        />
        <div className="scrolling-text">
          <span>
            This website is running in testing mode. If you have any complaints
            or feedback, please contact us on 0772416162 or 0777357620.
          </span>
        </div>

        <div className="dots-container">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentImageIndex === index ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
