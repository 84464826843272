import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { startLoading, stopLoading } from "../loading/loadingSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getCountryList = createAsyncThunk(
  "get/CountryList",
  async (_, { dispatch }) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(`${API_BASE_URL}/api/countries`);
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);
