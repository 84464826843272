import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShops } from "../../redux/shop/shopsThunk";
import "./SideBar.css";

const SideBar = ({ selectedShopId, onSelectShop }) => {
  const numericSelectedShopId = Number(selectedShopId);
  const dispatch = useDispatch();
  const hasFetched = useRef(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { shops } = useSelector((state) => state.shopData);

  useEffect(() => {
    if (!hasFetched.current && !shops.hasFetched) {
      dispatch(getShops());
      hasFetched.current = true;
    }
  }, [dispatch, shops.hasFetched]);

  const filteredShops = shops.data.filter((shop) =>
    shop.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="side-bar-container">
      <div className="search-container">
        <input
          type="text"
          placeholder="Search shops..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <button className="clear-button" onClick={clearSearch}>
            &times;
          </button>
        )}
      </div>
      <div className="shop-list-without-search-area">
        {filteredShops.length > 0 ? (
          filteredShops.map((shop, index) => (
            <div
              key={index}
              className={`shop-item ${numericSelectedShopId === shop.id ? "selected" : ""}`}
              onClick={() => onSelectShop(shop.id)}
            >
              {shop.name}
            </div>
          ))
        ) : (
          <div className="no-items-found">No shops found</div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
