import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { startLoading, stopLoading } from "../loading/loadingSlice";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getProductByShopID = createAsyncThunk(
  "get/productByShopID",
  async (id, { dispatch }) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(`${API_BASE_URL}/api/product-by-shop/${id}`);
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const getProductByID = createAsyncThunk(
  "get/productByID",
  async (id, { dispatch }) => {
    try {
      dispatch(startLoading());
      const response = await axios.get(`${API_BASE_URL}/api/product/${id}`);
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);

export const getProductAsSearchResults = createAsyncThunk(
  "get/productBySearchResults",
  async (searchData, { dispatch }) => { 
    try {
      dispatch(startLoading());
      const response = await axios.post(`${API_BASE_URL}/api/search/product`, searchData);
      dispatch(stopLoading());
      return response.data;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  }
);