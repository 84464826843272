import React, { useEffect, useState } from "react";
import "./MessageBox.css";

const MessageBox = ({ message, type }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 10000); // 10 seconds

      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!visible) return null;

  return <div className={`message-box ${type}`}>{message}</div>;
};

export default MessageBox;
