import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShops } from "../../../redux/shop/shopsThunk";

import "./ShopPage.css";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const mainImagePath = `${API_BASE_URL}/images/shop_images/main_image/`;

const ShopPage = () => {
  const dispatch = useDispatch();
  const hasFetched = useRef(false);
  const navigate = useNavigate();
  const { shops } = useSelector((state) => state.shopData);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedShopId, setSelectedShopId] = useState();

  const filteredShops = shops.data.filter((shop) =>
    shop.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const clearSearch = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    if (!hasFetched.current && !shops.hasFetched) {
      dispatch(getShops());
      hasFetched.current = true;
    }
  }, [dispatch, shops.hasFetched]);

  const handleShopClick = (shopId) => {
    setSelectedShopId(shopId);
    navigate(`/shops/${shopId}`);
  };
  return (
    <div className="shops-page-container">
      <div className="shops-page-search-container">
        <input
          type="text"
          placeholder="Search shops..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <button className="clear-button" onClick={clearSearch}>
            &times;
          </button>
        )}
      </div>
      <div className="shops-page-shop-list-container">
        {filteredShops.length > 0 ? (
          filteredShops.map((shop, index) => (
            <div
              key={index}
              className={`shops-page-shop-item ${
                selectedShopId === shop.id ? "selected" : ""
              }`}
              onClick={() => handleShopClick(shop.id)}
            >
              <div>
                <img
                  src={`${mainImagePath}${shop.main_image}`}
                  alt={shop.name}
                />
              </div>
              <div className="shop-details">
                <h2>{shop.name}</h2>
                <p>
                  <strong>Address:</strong> {shop.address}
                </p>
                {/* <p>
                  <strong>Phone Number:</strong> {shop.phone_number}
                </p> */}
                <p>
                  <strong>Open Time:</strong> {shop.open_time}
                </p>
                <p>
                  <strong>Close Time:</strong> {shop.close_time}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="no-items-found">No shops found</div>
        )}
      </div>
    </div>
  );
};

export default ShopPage;
