import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authThunk";
import { useNavigate } from "react-router-dom";

import "./NavBar.css";
import avatar from "../../images/nav-bar-images/avatar.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getProductAsSearchResults } from "../../redux/product/productThunk";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faBars,
  faHouse,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [isVisible, setIsVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { totalQuantity } = useSelector((state) => state.cart);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      navigate("/login");
    });
  };

  const handleAvatarClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleMyAccountClick = () => {
    navigate("/my-account");
    setDropdownVisible(false);
  };

  const productSearchHandler = () => {
    dispatch(getProductAsSearchResults({ product_name: searchWord }));
    navigate("/search-product");
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const clearInput = () => {
    setSearchWord("");
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="nav-bar-container">
      <div className="container-left">
        <div className="logo-items-container">
          <Link to="/" className="link-style">
            <h3>Ninety Seven</h3>
          </Link>
        </div>
        <div className="menu-icon-container" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} style={{ color: "white" }} size="2x" />
        </div>
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="side-bar-container-nav" onClick={closeSidebar}>
            <Link to="/">
              <div style={{ fontSize: "20px", cursor: "pointer" }}>
                <span style={{ marginRight: "15px" }}>
                  <FontAwesomeIcon icon={faHouse} style={{ color: "white" }} />
                </span>
                Home
              </div>
            </Link>

            <Link to="/shops" onClick={closeSidebar}>
              <div style={{ fontSize: "20px", cursor: "pointer" }}>
                <span style={{ marginRight: "15px" }}>
                  <FontAwesomeIcon
                    icon={faBagShopping}
                    style={{ color: "white" }}
                  />
                </span>
                Shop
              </div>
            </Link>
          </div>
        </div>
        <div className="menu-items-container">
          <ul>
            <li>
              <Link to="shops" className="link-item-style">
                Shop
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="container-right">
        <div
          className="search-container"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="search-input-field">
            <input
              type="text"
              placeholder="Search Product..."
              value={searchWord}
              style={{ width: "100%" }}
              onChange={(e) => setSearchWord(e.target.value)}
            />
            {searchWord && (
              <button className="clear-button" onClick={clearInput}>
                &times;
              </button>
            )}
          </div>
          <div className="search-icon" onClick={productSearchHandler}>
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: "white" }}
              className="search-icon"
            />
          </div>
        </div>
        <div className="login-items-container">
          <img
            src={avatar}
            alt="avatar"
            onClick={handleAvatarClick}
            style={{ cursor: "pointer" }}
          />
          {user && user.name && (
            <p className={`user-name ${isVisible ? "fade-in" : ""}`}>
              Welcome, {user.name}!
            </p>
          )}
          {dropdownVisible && (
            <div className="dropdown-menu">
              <button onClick={handleMyAccountClick}>My Account</button>
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}
        </div>
        {/* <div className="notification-icon-container">
          <Link to="/notifications" className="notification-link">
            <div className="notification-icon">
              <div className="bell"></div>
              <div className="clapper"></div>
            </div>
            {notificationsCount > 0 && (
              <span className="notification-badge">{notificationsCount}</span>
            )}
          </Link>
        </div> */}
        <div className="cart-icon-container">
          <Link to="/cart" className="cart-link">
            <div className="cart-icon">
              <div className="cart-handle"></div>
              <div className="cart-body"></div>
              <div className="cart-wheel cart-wheel-left"></div>
              <div className="cart-wheel cart-wheel-right"></div>
            </div>
            {totalQuantity >= 0 && (
              <span className="cart-badge">{totalQuantity}</span>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
