import { createSlice } from "@reduxjs/toolkit";
import { placeOrder } from "./cartThunk"; // Import the thunk from cartThunk.js

const initialState = {
  items: JSON.parse(localStorage.getItem("cartItems")) || [],
  totalQuantity: JSON.parse(localStorage.getItem("totalQuantity")) || 0,
  totalAmount: JSON.parse(localStorage.getItem("totalAmount")) || 0,
  orderStatus: null,
  orderError: null,
};

const saveCartToLocalStorage = (state) => {
  localStorage.setItem("cartItems", JSON.stringify(state.items));
  localStorage.setItem("totalQuantity", JSON.stringify(state.totalQuantity));
  localStorage.setItem("totalAmount", JSON.stringify(state.totalAmount));
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find(
        (item) => item.id === newItem.id && item.shop_id === newItem.shop_id
      );
      if (!existingItem) {
        state.items.push({
          id: newItem.id,
          price: newItem.price,
          quantity: newItem.quantity,
          totalPrice: newItem.price * newItem.quantity,
          shop_id: newItem.shop_id,
          product: newItem,
        });
        state.totalQuantity += newItem.quantity;
        state.totalAmount += newItem.price * newItem.quantity;
      } else {
        existingItem.quantity += newItem.quantity;
        existingItem.totalPrice += newItem.price * newItem.quantity;
        state.totalQuantity += newItem.quantity;
        state.totalAmount += newItem.price * newItem.quantity;
      }
      saveCartToLocalStorage(state);
    },
    removeItemFromCart(state, action) {
      const { id, shop_id } = action.payload;
      const existingItem = state.items.find(
        (item) => item.id === id && item.shop_id === shop_id
      );
      if (existingItem.quantity === 1) {
        state.items = state.items.filter(
          (item) => item.id !== id || item.shop_id !== shop_id
        );
        state.totalQuantity--;
        state.totalAmount -= existingItem.price;
      } else {
        existingItem.quantity--;
        existingItem.totalPrice -= existingItem.price;
        state.totalQuantity--;
        state.totalAmount -= existingItem.price;
      }
      saveCartToLocalStorage(state);
    },
    clearCart(state) {
      state.items = [];
      state.totalQuantity = 0;
      state.totalAmount = 0;
      saveCartToLocalStorage(state);
    },
    incrementItemQuantity(state, action) {
      const { id, shop_id } = action.payload;
      const existingItem = state.items.find(
        (item) => item.id === id && item.shop_id === shop_id
      );
      if (existingItem) {
        existingItem.quantity++;
        existingItem.totalPrice += existingItem.price;
        state.totalQuantity++;
        state.totalAmount += existingItem.price;
      }
      saveCartToLocalStorage(state);
    },
    decrementItemQuantity(state, action) {
      const { id, shop_id } = action.payload;
      const existingItem = state.items.find(
        (item) => item.id === id && item.shop_id === shop_id
      );
      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity--;
        existingItem.totalPrice -= existingItem.price;
        state.totalQuantity--;
        state.totalAmount -= existingItem.price;
      }
      saveCartToLocalStorage(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeOrder.pending, (state) => {
        state.orderStatus = "loading";
        state.orderError = null;
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.orderStatus = "succeeded";
        state.orderError = null;
        state.items = [];
        state.totalQuantity = 0;
        state.totalAmount = 0;
        saveCartToLocalStorage(state);
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.orderStatus = "failed";
        state.orderError = action.payload;
      });
  },
});

export const {
  addItemToCart,
  removeItemFromCart,
  clearCart,
  incrementItemQuantity,
  decrementItemQuantity,
} = cartSlice.actions;
export default cartSlice.reducer;
