import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  steps: [{ label: "HOME", path: "/", icon: "🏠", isPass: true }],
};

const stepMapSlice = createSlice({
  name: "stepMap",
  initialState,
  reducers: {
    addStep(state, action) {
      const newStep = action.payload;
      state.steps.push(newStep);
    },
    removeLastStep(state) {
      state.steps.pop();
    },
    setSteps(state, action) {
      state.steps = action.payload;
    },
  },
});

export const { addStep, removeLastStep, setSteps } = stepMapSlice.actions;
export default stepMapSlice.reducer;
